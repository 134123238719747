.info-text {
    width: 100%;
    background-color: #000;
}
.info-pic {
    width: 70%;
    height: 500px;
    margin: 0 auto;
}

.info-pic img {
    width: 100%;
    height: 500px
}
.info-text-content {
    width: 100%;
    text-align: center;
}

.info-text-content h3 {
    font-size: 34px;
    padding: 40px 0;
    color: #0d7377;
}

.info-text-content p {
    width: 70%;
    line-height: 2;
    margin: 0 auto;
    text-align: justify;
}

@media (max-width: 700px){
    .info-pic {
        width: 90%;
        height: 300px;
    }   
    .info-pic img {
        height: 300px
    }
    .info-text-content h3 {
        font-size: 30px;
        padding: 20px 0;
    }
    
    .info-text-content p {
        width: 80%;
        line-height: 2;
        font-size: 14px;
    }
}

@media (min-width: 701px) {
    .info-pic {
        width: 70%;
        height: 400px;
    }   
    .info-pic img {
        height: 400px
    }
    .info-text-content h3 {
        font-size: 36px;
        padding: 30px 0;
    }
    
    .info-text-content p {
        width: 70%;
        font-size: 16px;
    }
}

@media (min-width: 1024px){
    .info-pic {
        width: 60%;
        height: 500px;
    }   
    .info-pic img {
        height: 500px
    }
}