.home {
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #000;
}
.home h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 0px 4px 42px rgba(255, 255, 255, 1);
    z-index: 5;
}

.my-picture {
    width: 300px;
    height: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 700px){
    .home {
        height: 600px;
    }
    .my-picture {
        width: 200px;
        height: 400px;
        top: 40%;
    }
    .home h1 {
        width: 300px;
        font-size: 18px !important;
        left: 60%;
        top: 40%;
    }
}

@media (min-width: 701px) and (max-width: 1023px){
    .home h1 {
        width: 70%;
        font-size: 34px;
    }
}

@media(min-width: 1024px){
    .home h1 {
        width: 70%;
        font-size: 40px;
    }
}
