.expertise {
    width: 100%;
    background-color: #000;
}

.expertise-container {
    width: 70%;
    border-radius: 20px;
    margin: 0px auto;
    padding: 40px 0;
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 700px){
    .expertise-container {
        display: block;
        width: 90%;
    }
}

@media (min-width: 701px) and (max-width: 1023px){
    .expertise-container {
        width: 90%;
    }
}

@media (min-width: 1024px){
    .expertise-container {
        width: 70%;
    } 
}



