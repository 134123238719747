* {
  margin:0;
  padding:0;
  box-sizing:border-box;
  list-style:none;
  text-decoration:none;
}

body {
  font-family: 'Oswald', sans-serif;
  font-family: 'Poppins', sans-serif;
}

h2 {
  color: #ebebe3;
  font-size: 32px;
  text-align: center;
  padding-top: 40px;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: white;
}


::-webkit-scrollbar-thumb {
  background-color: black;
}

::-webkit-scrollbar-track {
  background-color: white; 
}





