.info-home {
    width: 100%;
    background-color: #000;
}

.info-header {
    width: 100%;
}

.info-header img {
    position: relative;
    margin: 20px 0 0 20px;
    top: 0;
    left: 0;
    padding: 0;
}

.info-home-content {
    width: 100%;
    text-align: center;
}
.info-home-content h1 {
    font-size: 80px;
    padding: 40px 20px;
    color: #14ffec;

}

.info-home-content p {
    width: 70%;
    margin: 0 auto;
    line-height: 2;
    padding-bottom: 40px;
    color: #a7bcb9;
}

.info-home-content button {
    border: none;
    border-radius: 10px;
    padding: 20px 30px;
    background-color: #14ffec;
    font-size: 24px;
    margin: 0 auto;
    display: block;
}

.info-home-content a {
    color: #fff;
}

@media (max-width: 700px){
    .info-home-content h1 {
        font-size: 40px;
        padding: 30px 20px;
    }
    .info-home-content p {
        width: 80%;
        line-height: 1.5;
        padding-bottom: 30px;
    }   
    .info-home-content button {
        padding: 10px 20px;
        font-size: 18px;
    }
}

@media (min-width: 701px) {
    .info-home-content h1 {
        font-size: 50px;
    }
    .info-home-content p {
        width: 70%;
        line-height: 2;
        padding-bottom: 36px;
    }   
    .info-home-content button {
        padding: 20px 20px;
        font-size: 24px;
    }
}